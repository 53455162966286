.WhatsOnGuide {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.WhatsOnGuide__dynamicContent {
  width: 90%;
}
.WhatsOnGuide__articles {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
}
.WhatsOnGuideAdsDisplay {
  width: 302px;
  padding-left: 15px;
  padding-right: 20px;
  padding-top: 9px;
  padding-bottom: 14px;
}
.WhatsOnGuide__whatsOnHeading {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 24px;
  width: 100%;
  text-align: center;
  color: #3B3B3B;
}
.WhatsOnGuide__whatsOnHeadingInTV {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 40px;
  width: 100%;
  text-align: center;
  color: #3B3B3B;
}
.WhatsOnGuide__comingSoonText {
  font-size: 40px;
  color: var(--ion-color-white);
}
.WhatsOnGuide__informationText {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
.WhatsOnGuide__ComingSoonDescription {
  font-size: 20px;
  color: var(--ion-color-white);
  margin-top: 40px;
  text-align: center;
  white-space: pre-wrap;
}
.WhatsOnGuide__information {
  width: 80%;
  height: auto;
  background-color: #ac1a1b;
  margin: 50px auto;
}


/* @media screen and (min-width: 1000px) and (max-width: 2000px) { */
  .WhatsOnGuide__Filter_wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
/* } */