.BLItem_check {
  position: absolute;
  color: white;
  z-index: 4;
}

.BLItem_check_md {
  @extend .BLItem_check;
  right: 7px;
  top: 5px;
}

.BLItem_triangle {
  width: 0;
  height: 0;
  border-right-style: solid;
  position: absolute;
  z-index: 3;
}

.BLItem_triangle_md {
  @extend .BLItem_triangle;
  border-bottom: 70px solid transparent;
  border-right-width: 75px;
  right: -1px;
  top: -1px;
}

.BLItem_check_sm {
  @extend .BLItem_check;
  right: 5px;
  top: 2px;
}

.BLItem_triangle_sm {
  @extend .BLItem_triangle;
  border-bottom: 50px solid transparent;
  border-right-width: 55px;
  right: 0px;
  top: 0px;
}

.BLItem_check_xsm {
  @extend .BLItem_check;
  right: 3px;
  top: 2px;
}

.BLItem_triangle_xsm {
  @extend .BLItem_triangle;
  border-bottom: 45px solid transparent;
  border-right-width: 45px;
  right: 0px;
  top: 0px;
}