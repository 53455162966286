.GuideItemDesktop {
  width: 100%;
  max-height: 240px;
  margin-bottom: 11px;
  display: flex;
  border-radius: 5px;
  background-color: white;
  transition: 0.5s all ease-in-out;
}
.GuideItemDesktop_Expand {
  max-height: 1000px;
}
.GuideItemDesktop__images_wrapper,
.GuideItemDesktop__details {
  width: 50%;
  min-height: 207px;
}

.GuideItemDesktop__details {
  padding-left: 25px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 15px;
  overflow: hidden;
  position: relative;
}
.GuideItemDesktop__headline {
  display: flex;
  justify-content: space-between;
}

.GuideItemDesktop__headline > * + * {
  margin-left: 15px;
}
.GuideItemDesktop__data {
  display: flex;
  padding-top: 15px;
}
.GuideItemDesktop__data > ul {
  width: 50%;
}
.GuideItemDesktop__data > ul > li {
  margin-bottom: 10px;
}
.GuideItemDesktop__seeMore {
  color: var(--ion-color-primary);
  cursor: pointer;
  padding-top: 10px;
}


.GuideItemDesktop__TV {
    max-height: none;
}
.GuideItemDesktop__TV .GuideItemDesktop__images_wrapper,
.GuideItemDesktop__TV .GuideItemDesktop__details {
  min-height: max-content;
  height: auto;
}
.GuideItemDesktop__TV .GuideItemDesktop__data {
    font-size:26px;
}
.GuideItemDesktop__headingIsInTVVertical{
  color: #545F8D;
  font-size: 40px;
  max-width: fit-content;
}
.GuideItemDesktop__heading{
  color: #545F8D;
  font-size: 18px;
  max-width: fit-content;
}
.GuideItemDesktop__informationText {
  padding-top: 5px;
}
.GuideItemDesktop__informationTextIsInTVVertical {
  padding-top: 5px;
  font-size: 28px;
}