.MainFooterNew {
  height: 70px;
}
.MainFooter_icon {
  color: var(--ion-color-white);
}

.MainFooter_iconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 16px 0px;
}

.MainFooter_iconWrapper_selected {
  @extend .MainFooter_iconWrapper;
  background-color: var(--ion-color-tertiary);
  border-radius: 5px 5px 0 0;
}
