.ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}
