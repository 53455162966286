.swiperContainer {
  height: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
  max-width: 100vw;
  width: 100%;
  overflow: hidden;
}

.swiperContainer>.swiper-wrapper {
  max-height: 100%;
  height: 100%t;
  display: flex;
}

.swiperSlide {
  width: auto;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slider-prev-arrow,
.slider-next-arrow {
  position: absolute;
  z-index: 2;
  bottom: 50%;
}

.slider-prev-arrow {
  left: 10px;
}

.slider-next-arrow {
  right: 10px;
}

/* .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color:#000;
  opacity: 1;
  background: rgba(0,0,0,0.2);
}
.swiper-pagination-bullet-active {
  color:#fff;
  background: #007aff;
} */