.ScrollComponent {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--ion-color-primary);
  height: 100%;
  overflow: auto;
}

.CountdownWrapper {
  border: 1px solid var(--ion-color-warner-teal);
  border-radius: 5px;
  flex: 0;
}

.LoaderContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 340px) {
  .Tile {
    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  }
}

@media (min-width: 600px) {
  .Tile {
    flex: 0 0 calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(2.4 / var(--ion-grid-columns, 12)) * 100%);
  }
}

@media (min-width: 821px) {
  .Tile {
    flex: 0 0 calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(3 / var(--ion-grid-columns, 12)) * 100%);
  }
}