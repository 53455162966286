.BLCheckBox {
  --background: transparent;
  --background-focused: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
  --ripple-color: var(--background-activated);
}

.BLCheckBox::part(native) {
  padding-inline-start: unset;
}

.bl-checkbox-dining-party>ion-checkbox {
  --checkbox-background: white;
  --checkbox-background-checked: var(--ion-color-secondary);
  --checkmark-width: 3px;
  --border-radius: 5px;
  --border-width: 2px;
  --checkmark-color: white;
  --border-color: var(--ion-color-secondary);
  --border-color-checked: var(--ion-color-secondary);
  --size: 24px;
}

.bl-checkbox-limit-height {
  --min-height: 30px;
}

.bl-checkbox-limit-height>ion-checkbox {
  --checkbox-background-checked: var(--ion-color-secondary);
  --checkmark-width: 3px;
  --border-radius: 5px;
  --border-width: 2px;
  --checkmark-color: white;
  --border-color: var(--ion-color-secondary);
  --border-color-checked: var(--ion-color-secondary);
}

.bl-checkbox-limit-height>ion-checkbox::part(container) {
  width: 24px;
  height: 24px;
}

.bl-checkbox-radio> .checkbox-icon::part(container) {
  background: red;
}

.bl-checkbox-radio-checked {
  background: white;
}