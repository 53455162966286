
@media (max-width: 481px) {
    .box-size {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    }
}

@media (min-width: 482px) and (max-width: 821px) {
    .box-size {
        flex: 1 0 calc(calc(5.8 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(5.8 / var(--ion-grid-columns, 12)) * 100%);
        max-width: 100%;
    }
    .box-size-wrapper ion-col:nth-child(odd):last-child {
        max-width: calc(calc(5.85 / var(--ion-grid-columns, 12)) * 100%);
    }
}

@media (min-width: 821px) {
    .box-size {
        max-width: 340px;
        min-width: 170px;
        flex: 0 0 calc(calc(5 / var(--ion-grid-columns, 12)) * 100%);
    }
}