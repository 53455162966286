
.HomePageTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  padding: 16px 8px;
  border-radius: 8px;
}

.UnavailableTriangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 45px 45px 0;
  text-align: right;
  border-top-right-radius: 5px;
}

.UnavailableTriangle > * {
  position: relative;
  top: 3px;
  right: -22px;
  cursor: pointer;
}

.TileBadge {
  position: absolute;
  top: 5px;
  left: 55%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: var(--ion-color-tertiary);
  border: 1px solid;
}