.Kiosk {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
  flex-direction: column;
  height: 80vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
}

.Login__btn__container {
  /* position: fixed;
  right: 20px;
  bottom: 20px; */
  position: absolute;
  bottom: calc(100vh - 78vh);
}

.Login__btn {
  min-height: calc(100vh - 92vh);
}

@media screen and (min-width: 450px) and (max-width: 650px) {
  .Kiosk__img {
    width: 90%;
  }
}

@media screen and (min-width: 280px) and (max-width: 450px) {
  .Kiosk__img {
    width: 95%;
    height: 75vh
  }

  .Login__btn {
    position: absolute;
    bottom: calc(100vh - 85vh);
  }
}
