.diningDateButton {
    min-width: 80px;
    border-radius: 7px
}

.daySelector {
    width: 300px !important;
}

@media (max-width: 481px) {
    .daySelector {
        width: 100% !important;
    }
}