.icon {
  margin-top: 4px;
  margin-right: 6px;
  box-shadow: 0 3px 2px -2px gray;
  border: 1px solid #ba5680;
  padding: 2px 14px;
  border-radius: 16px;
}

@media screen and (min-width: 821px) {
  .Modal {
    --height: 80%;
  }
}
