.heading {
    font-size: 28px;
    color: var(--ion-color-text-grey); 
    text-align: center; 
}
.informationText {
    font-size: 16px;
    color: var(--ion-color-text-grey); 
    text-align: center; 
    margin-top: 35px;
}