.FAQSearch {
  width: 100%;
  height: 55px;
  background-color: var(--ion-color-white);
  border-radius: 5px;
  display: flex;
}
.FAQSearch__search  {
  height: 100%;
  width: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-primary);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}



.FAQSearch__submit {
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  cursor: pointer;
}
.FAQSearch__input, .FAQSearch__input_para {
  width: calc(100% - 59px);
  height: 100%;
  display: flex;
  color: #000;
  padding-left: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
}
.FAQSearch__input_para {
  align-items: center;
}
.FAQSearch__input::placeholder {
  color: #000;
  white-space: pre;
}
@media only screen and (min-width: 228px) and (max-width: 610px) {
  .FAQSearch__input_para {
    font-weight: 14px;
  }
}
