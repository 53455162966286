.MainWrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

.MainContentWrapper {
  flex: 2;
  display: flex;
  padding: 0;
}

.ContentWrapper {
  flex: 1 1;
  border: none;
}

.ScrollWrapper {
  position: absolute;
  top: 0; 
  bottom: 0; 
  left: 0; 
  right: 0; 
  overflow-y: auto;
}

.SideNav {
  display: none;
}

.SideNavWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: var(--ion-color-primary);
}

@media (min-width: 821px) {
  .SideNav {
    display: block;
    flex: 0 0 280px;
  }
}