.bl-text-xxx-small {
  font-size: 12px !important;
}

.bl-text-xx-small {
  font-size: 14px !important;
}

.bl-text-x-small {
  font-size: 16px !important;
}

.bl-text-small {
  font-size: 18px !important;
}

.bl-text-body {
  font-size: 17px !important;
}

.bl-text-medium {
  font-size: 20px !important; //h3
}

.bl-text-large {
  font-size: 22px !important;
}

.bl-text-x-large {
  font-size: 24px !important; //h2
}

.bl-text-xx-large {
  font-size: 26px !important;
}

.bl-text-xxx-large {
  font-size: 30px !important; //h1
}

.bl-text-regular {
  font-weight: 400;
}

.bl-text-bold {
  font-weight: bold;
}

.bl-text-semibold {
  font-weight: 600 !important;
}

.bl-text-light {
  font-weight: 300;
}

.bl-text-italic {
  font-style: italic;
}

.bl-text-capitalize {
  text-transform: capitalize;
}

.bl-text-uppercase {
  text-transform: uppercase;
}

.bl-text-lowercase {
  text-transform: lowercase;
}

.bl-text-underline {
  text-decoration: underline;
}

.bl-text-line {
  /* .bl-text-line-through {  */
  text-decoration: line-through;
}

.bl-text-pointer {
  cursor: pointer;
}

.bl-text-inline-block {
  display: inline-block;
}

.bl-text-disabled {
  opacity: 0.6;
}

.bl-text-force-center {
  display: flex;
  align-items: center;
}

.bl-text-menu-button-color {
  color: #444444;
}

.bl-text-break-word {
  word-break: break-word;
}

.bl-text-crimson-pro {
  font-family: "CrimsonPro" !important;
}

.bl-text-open-sans {
  font-family: "Open Sans" !important;
}


@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Regular.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Bold.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-SemiBold.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Light.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Italic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-BoldItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-LightItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Black.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-BlackItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-ExtraBold.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-ExtraLight.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-ExtraLightItalic.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-Medium.ttf");
}

@font-face {
  font-family: "CrimsonPro";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Crimson_Pro/static/CrimsonPro-MediumItalic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/OpenSans/OpenSans-Bold.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/OpenSans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/OpenSans/OpenSans-Light.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/OpenSans/OpenSans-Italic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/OpenSans/OpenSans-BoldItalic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/OpenSans/OpenSans-LightItalic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/OpenSans/OpenSans-ExtraBold.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/OpenSans/OpenSans-Medium.ttf");
}

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/OpenSans/OpenSans-MediumItalic.ttf");
}