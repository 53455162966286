.timer-wrapper {
  display: flex;
  justify-content: center;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: var(--ion-color-white);
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: flex-end;
}

.value {
  color: var(--ion-color-white);
  font-size: 24px;
}
