.BLLoader_backdrop {
  background-color: #000;
  opacity: 0.4;
}

.BLLoader_content {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    width: max-content;
    max-width: 300px;
}
