.BLItem {
  width: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
}

.bl-white {
  background: var(--ion-color-white);
}
.bl-gray {
  background: var(--ion-color-light);
}

.bl-restaurant-card > ion-row:first-child > ion-col {
  height: 120px;
}
.BLItem_triangle {
  width: 0;
  height: 0;
  border-bottom: 50px solid transparent;

  border-right-width: 55px;
  border-right-style: solid;
  border-right-color: var(--ion-color-white);
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 1;
}
.BLItem_check {
  position: absolute;
  color: var(--ion-color-secondary);
  right: 0;
  top: 0;
  z-index: 2;
}
.BLItem_triangle_left {
  width: 0;
  height: 0;
  border-bottom: 35px solid transparent;

  border-left-width: 35px;
  border-left-style: solid;
  border-left-color: var(--ion-color-secondary);
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 1;
}
.BLItem_check_left {
  position: absolute;
  color: white;
  left: 0px;
  top: 0px;
  z-index: 2;
}
.BLItemCheckIcon {
  width: 32px;
  height: 32px;
}
.BLItemCheckIcon_left {
  width: 20px;
  height: 20px;
}
.BLItemCenterText {
  text-align: center;
}

.BLItem_unavailable_banner {
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--ion-color-text-grey); 
  background-color: rgba(0, 0, 0, 0.38);
  color: var(--ion-color-white);
  z-index: 2;
}

.BLItem_lastfew_banner {
  position: relative;
  padding: 5px 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: var(--ion-color-text-grey); 
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--ion-color-white);
  border: 5px 5px 0 0;
}

.BLItem_isUnavailable {
  pointer-events: none;
  cursor: default;
}
.BLItem_isUnavailable > ion-row:first-child {
  opacity: 0.38;
}
.BLItem_isSelected {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.8;
  background: var(--ion-color-secondary);
}

.bl-category-card-container {
  min-width: 168px;
}

.bl-category-card-content {
  background: var(--ion-color-light);
  text-align: center;
  align-items: center;
}

.bl-summary-card-image {
  height: 136px;
}
