/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Open Sans";
  -webkit-tap-highlight-color: transparent;

  --ion-color-primary: #153246;
  --ion-color-primary-rgb: 22, 50, 70;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #132c3e;
  --ion-color-primary-tint: #2d4759;

  --ion-color-secondary: #457c9a;
  --ion-color-secondary-rgb: 69, 124, 154;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3d6d88;
  --ion-color-secondary-tint: #5889a4;

  --ion-color-tertiary: #ba5680;
  --ion-color-tertiary-rgb: 186, 86, 128;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #a44c71;
  --ion-color-tertiary-tint: #c1678d;

  --ion-color-success: #729b5a;
  --ion-color-success-rgb: 114, 155, 90;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #64884f;
  --ion-color-success-tint: #80a56b;

  --ion-color-warning: #e8d862;
  --ion-color-warning-rgb: 232, 216, 98;
  --ion-color-warning-contrast: #333333;
  --ion-color-warning-contrast-rgb: 51, 51, 51;
  --ion-color-warning-shade: #ccbe56;
  --ion-color-warning-tint: #eadc72;

  --ion-color-danger: #c3666a;
  --ion-color-danger-rgb: 195, 102, 106;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ac5a5d;
  --ion-color-danger-tint: #c97579;

  --ion-color-light: #f2f2f4;
  --ion-color-light-rgb: 242, 242, 244;
  --ion-color-light-contrast: #333333;
  --ion-color-light-contrast-rgb: 51, 51, 51;
  --ion-color-light-shade: #d5d5d7;
  --ion-color-light-tint: #f3f3f5;

  --ion-color-info: #d9ebee;
  --ion-color-info-rgb: 217, 235, 238;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #bfcfd1;
  --ion-color-info-tint: #ddedf0;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-box-shadow: #00000029;

  --ion-color-warner-teal: #56B4BA;
	--ion-color-warner-teal-rgb: 86,180,186;
	--ion-color-warner-teal-contrast: #000000;
	--ion-color-warner-teal-contrast-rgb: 0,0,0;
	--ion-color-warner-teal-shade: #4c9ea4;
	--ion-color-warner-teal-tint: #67bcc1;

  --ion-color-accent-pale-blue: #E2E8EB;
	--ion-color-accent-pale-blue-rgb: 226,232,235;
	--ion-color-accent-pale-blue-contrast: #000000;
	--ion-color-accent-pale-blue-contrast-rgb: 0,0,0;
	--ion-color-accent-pale-blue-shade: #c7cccf;
	--ion-color-accent-pale-blue-tint: #e5eaed;

  --ion-color-accent-pale-grey: #F1F3F3;
	--ion-color-accent-pale-grey-rgb: 241,243,243;
	--ion-color-accent-pale-grey-contrast: #000000;
	--ion-color-accent-pale-grey-contrast-rgb: 0,0,0;
	--ion-color-accent-pale-grey-shade: #d4d6d6;
	--ion-color-accent-pale-grey-tint: #f2f4f4;

  --ion-color-text-grey: #555a66;
	--ion-color-text-grey-rgb: 85,90,102;
	--ion-color-text-grey-contrast: #ffffff;
	--ion-color-text-grey-contrast-rgb: 255,255,255;
	--ion-color-text-grey-shade: #4b4f5a;
	--ion-color-text-grey-tint: #666b75;

  --ion-color-mid-grey: #B5B6B8;
	--ion-color-mid-grey-rgb: 181,182,184;
	--ion-color-mid-grey-contrast: #000000;
	--ion-color-mid-grey-contrast-rgb: 0,0,0;
	--ion-color-mid-grey-shade: #9fa0a2;
	--ion-color-mid-grey-tint: #bcbdbf;
}

/*
     * Dark Colors
     * -------------------------------------------
     */
/* @media (prefers-color-scheme: dark) {
    
  
    body {
    	--ion-color-primary: #545F8D;
	--ion-color-primary-rgb: 82,94,139;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #48537a;
	--ion-color-primary-tint: #636e97;

	--ion-color-secondary: #699DB0 ;
	--ion-color-secondary-rgb: 105,157,176;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #5c8a9b;
	--ion-color-secondary-tint: #78a7b8;

	--ion-color-tertiary: #345867;
	--ion-color-tertiary-rgb: 58,89,98;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #334e56;
	--ion-color-tertiary-tint: #4e6a72;

	--ion-color-success: #729B5A ;
	--ion-color-success-rgb: 114,155,90;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #64884f;
	--ion-color-success-tint: #80a56b;

	--ion-color-warning: #ECD748;
	--ion-color-warning-rgb: 236,215,72;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #d0bd3f;
	--ion-color-warning-tint: #eedb5a;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #707070;
	--ion-color-medium-rgb: 112,112,112;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #636363;
	--ion-color-medium-tint: #7e7e7e;

	--ion-color-light: #F2F2F4;
	--ion-color-light-rgb: 242,242,244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d5d5d7;
	--ion-color-light-tint: #f3f3f5;
    } */

/*
     * iOS Dark Theme
     * -------------------------------------------
     */

/* .ios body {
      --ion-background-color: #000000;
      --ion-background-color-rgb: 0, 0, 0;
  
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
  
      --ion-color-step-50: #0d0d0d;
      --ion-color-step-100: #1a1a1a;
      --ion-color-step-150: #262626;
      --ion-color-step-200: #333333;
      --ion-color-step-250: #404040;
      --ion-color-step-300: #4d4d4d;
      --ion-color-step-350: #595959;
      --ion-color-step-400: #666666;
      --ion-color-step-450: #737373;
      --ion-color-step-500: #808080;
      --ion-color-step-550: #8c8c8c;
      --ion-color-step-600: #999999;
      --ion-color-step-650: #a6a6a6;
      --ion-color-step-700: #b3b3b3;
      --ion-color-step-750: #bfbfbf;
      --ion-color-step-800: #cccccc;
      --ion-color-step-850: #d9d9d9;
      --ion-color-step-900: #e6e6e6;
      --ion-color-step-950: #f2f2f2;
  
      --ion-toolbar-background: #0d0d0d;
  
      --ion-item-background: #1c1c1c;
      --ion-item-background-activated: #313131;
    }
   */
/*
     * Material Design Dark Theme
     * -------------------------------------------
     */

/* .md body {
      --ion-background-color: #121212;
      --ion-background-color-rgb: 18, 18, 18;
  
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
  
      --ion-border-color: #222222;
  
      --ion-color-step-50: #1e1e1e;
      --ion-color-step-100: #2a2a2a;
      --ion-color-step-150: #363636;
      --ion-color-step-200: #414141;
      --ion-color-step-250: #4d4d4d;
      --ion-color-step-300: #595959;
      --ion-color-step-350: #656565;
      --ion-color-step-400: #717171;
      --ion-color-step-450: #7d7d7d;
      --ion-color-step-500: #898989;
      --ion-color-step-550: #949494;
      --ion-color-step-600: #a0a0a0;
      --ion-color-step-650: #acacac;
      --ion-color-step-700: #b8b8b8;
      --ion-color-step-750: #c4c4c4;
      --ion-color-step-800: #d0d0d0;
      --ion-color-step-850: #dbdbdb;
      --ion-color-step-900: #e7e7e7;
      --ion-color-step-950: #f3f3f3;
  
      --ion-item-background: #1a1b1e;
    }
  
    ion-title.title-large {
      --color: white;
    }
  } */
