.Login {
  padding-top: env(safe-area-inset-top);
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  align-items: center;
}

.Separator {
  height: 35%;
}

.SplashContent {
  height: 260px;
  overflow: auto;
}

.LoginForm__AlertImg {
  width: 100%;
  height: 250px;
}

@media screen and (min-width: 821px) {
  .Login {
    width: 390px;
    min-width: 390px;
  }
}
