.MainTitleNew {
    z-index: 1;
}
.MainTitleNew > ion-toolbar {
    --min-height: 80px;
    --padding-end: 16px;
    --padding-start: 16px;
}
.MainTitleNew_text {
    color: var(--ion-color-white);
    text-align: center;
    font: normal normal bold 24px/24px Crimson Pro;
    opacity: 1;
}
.MainTitleNew_button {
    height: 56px !important;
    margin: 0 !important;
    --box-shadow: none !important;
    font-size: 17px;
    font-weight: 600;
}
.MainTitleNew_button:not(:last-child) {
    margin-right: 8px !important;
}

.MainTitleNew_icon {
    color: var(--ion-color-white);
    --ionicon-stroke-width: 20px;
}
.MainTitleNew_iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 280px) and (max-width: 821px) {
    .MainTitleNew > ion-toolbar {
        --min-height: 80px;
    }
  }