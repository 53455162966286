:root {
  --btn-primary-bg-color: var(--ion-color-tertiary);
  --btn-secondary-bg-color: var(--ion-color-secondary);
  --btn-tertiary-bg-color: var(--ion-color-light);

  --btn-primary-text-color: var(--ion-color-white);
  --btn-secondary-text-color: #38414b;
}

.bl-button-base {
  height: 56px;
  cursor: pointer;
}

/* primary and time buttons */
.bl-button-primary {
  @extend .bl-button-base;
  height: 56px;
  color: var(--btn-primary-text-color);
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-white);
  --border-radius: 7px;
  min-width: 100px;

  --background: var(--btn-primary-bg-color);
  --background-activated: var(--btn-primary-bg-color);
  --background-hover: var(--btn-primary-bg-color);
}

.bl-button-primary-outlined {
  @extend .bl-button-base;
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--btn-secondary-bg-color);
  --border-radius: 7px;
  --background: var(--btn-primary-text-color);
  --background-activated: var(--btn-secondary-bg-color);
  color: var(--btn-secondary-bg-color);
}

.bl-button-time-primary {
  @extend .bl-button-base;
  min-width: 110px;
  --background: var(--btn-primary-bg-color);
  --background-activated: var(--btn-primary-bg-color);
  --background-hover: var(--btn-primary-bg-color);
  --border-radius: 10px;
  --padding-start: 8px;
  --padding-end: 8px;
}

.bl-button-time-outlined {
  @extend .bl-button-base;
  color: var(--btn-secondary-text-color);
  border: 2px solid var(--btn-secondary-bg-color);
  min-width: 110px;
  --border-radius: 10px;
  border-radius: 10px;

  --background: var(--btn-primary-text-color);
  --background-activated: var(--btn-primary-text-color);
  --background-hover: var(--btn-primary-text-color);
  --padding-start: 8px;
  --padding-end: 8px;
}

/* primary and time buttons */

/* Secondary and secondary mini buttons */
.bl-button-secondary {
  color: var(--btn-primary-text-color);
  cursor: pointer;

  --border-radius: 7px;
  --background: var(--btn-secondary-bg-color);
  --background-activated: var(--btn-secondary-bg-color);
  --background-hover: var(--btn-secondary-bg-color);
}

.bl-button-medium {
  height: 40px;
  min-width: 80px;
  cursor: pointer;
}

/* Secondary and secondary mini buttons */

/* symbol container button */
.bl-button-symbol-container {
  height: 24px;
  width: 24px;
  color: var(--btn-primary-text-color);

  --padding-start: 0px;
  --padding-end: 0px;
  --border-radius: 5px;
  --background: var(--btn-primary-bg-color);
  --background-activated: var(--btn-primary-bg-color);
  --background-hover: var(--btn-primary-bg-color);
}

/* symbol container button */

/* underlined button */

.bl-button-primary-underlined {
  @extend .bl-button-base;
  color: var(--btn-secondary-bg-color);
  border: none;
  text-decoration: underline;
  --background: transparent;
  --background-activated: transparent;
  --background-hover: transparent;
  --box-shadow: none;
  font-weight: 600;
}

/* underlined button */
.bl-button-underline-height {
  height: 30px !important;
}

/* iconText button */

.bl-button-icon-text {
  color: var(--btn-secondary-bg-color);
  --background: transparent;
  --background-activated: transparent;
  --box-shadow: none;
  font-weight: 600;
}

/* iconText-outlined button */

.bl-button-outlined-icon-text {
  @extend .bl-button-base;
  border: 2px solid var(--btn-secondary-bg-color);
  border-radius: 7px;
  color: var(--btn-secondary-bg-color);
  --background: transparent;
  --background-activated: transparent;
  --box-shadow: none;
  font-weight: 600;
}

/* UI type button */
.bl-button-ui {
  height: 30px;
  min-height: 30px !important; // override button default
  color: var(--btn-primary-bg-color);
  --border-style: solid;
  --border-width: 2px;
  --border-radius: 20px;
  border-radius: 20px;
  --background: var(--ion-color-white);
  --background-activated: var(--ion-color-white);
  --background-hover: var(--ion-color-white);
  font-size: revert-layer;
}

.bl-button-ui-small {
  @extend .bl-button-ui;
  width: 75px;
}

.bl-button-ui-small > ion-row > ion-col > ion-icon {
  height: 20px;
  width: 20px;
  margin: 0px !important;
}

.bl-button-ui-small > ion-row > ion-col > ion-text {
  font-size: 14px !important;
  padding-right: 8px;
}

.bl-button-ui > ion-row > ion-col > ion-icon {
  height: 20px;
  width: 20px;
}

.bl-button-ui > ion-row > ion-col > ion-text {
  font-size: 14px !important;
  padding-right: 8px;
}

.bl-button-no-hover {
  --background-hover: transparent;
}

.bl-button-small {
  height: 24px;
  min-width: 75px;
  cursor: pointer;
}

.bl-button-uppercase {
  text-transform: uppercase;
}

.bl-button-transparent {
  padding: 0 !important;
  letter-spacing: 0 !important;
  color: var(--btn-secondary-bg-color);
  --padding-start: 0 !important;
  --background: transparent;
  --background-activated: transparent;
  --box-shadow: none;
  font-weight: 600;
}
