.policies {
  height: 100%;
  overflow: auto;
  width: 100%;
  overflow-wrap: break-word;
}
.policies *::after {
  display: none !important;
}
.policies table {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
  overflow-x: auto !important;
  display: block !important;
}
.policies ul {
  list-style-type: disc;
}
.privacy p:first-of-type {
  font-size: 24px;
  font-weight: bold;
}
.backToTop {
  position: absolute;
  bottom: 20px;
  right: 36px;
}
.otnotice-content {
  max-width: 100%;
}

@media screen and (min-width: 821px) {
  .Modal {
    --height: 80%;
  }
}