.slider {
  width: 100%;
  height: 100.2%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  left: -1.2px;
}

.leftArrow,
.rightArrow {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 4;
  cursor: pointer;
}

.leftArrow>svg,
.rightArrow>svg {
  width: 20px;
  height: 20px;
}

.leftArrow {
  left: 10px;
  /* top: calc(50% - 15px); */
}

.rightArrow {
  /* top: calc(50% - 15px); */
  right: 10px;
}
.dotsWrapper_manual,
.dotsWrapper_auto {
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dotsWrapper_auto {
  position: relative;
}

.dotsWrapper_manual_nav {
  width: 10px;
  height: 10px;
  border: 1px solid white;
  border-radius: 50%;
  margin-right: 10px;
}
.dotsWrapper_auto_nav {
  margin-right: 15px;
  width: 100px;
}
.dotsWrapper_auto_nav_index {
  color: #806a64;
  font-size: 14px;
}

.dotsWrapper_auto_nav {
  margin-right: 15px;
  width: 100px;
}

.dotsWrapper_auto_nav_index {
  color: #806a64;
  font-size: 14px;
}

.imageWrapper {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.7s all;
}

.slideImage {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.slider__image_icons {
  position: absolute;
  bottom: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: var(--ion-color-white);
}

.slider__image_icons>*+* {
  margin-left: 15px;
}

.slideImage img {
  object-position: center center;
}

.ImageCarousel__login_info {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1350px) and (max-width: 1530px) {
  .dotsWrapper_auto_nav {
    width: 50px;
  }
}

@media screen and (min-width: 228px) and (max-width: 1349px) {
  .dotsWrapper_auto_nav {
    margin-top: 20px;
    width: 30px;
  }
}

@media screen and (min-width: 228px) and (max-width: 1300px) {
  .dotsWrapper_auto {
    padding-left: 0px;
  }
}

.slideImage__banner {
  position: absolute;
  z-index: 1;
  font-size: 20px;
  top: 20px;
  color: var(--ion-color-white);
  font-weight: bold;
  background-color: #a6a6a6;
  padding-left: 5px;
  padding-right: 5px;
}