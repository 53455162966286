.BlurryWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #00000029;
  backdrop-filter: blur(30px);
  --webkit-backdrop-filter: blur(30px);
  padding: var(--bl-padding-s);
  z-index: 2;
  height: auto;
  overflow: auto;
}