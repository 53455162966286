.loader {
    width: 25px;
    height: 25px;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-left: 20px;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
.Loader_transparent {
  --background: transparent;
  --spinner-color: var(--ion-color-primary);
  ion-spinner {
    width: 50px;
    height: 50px;
  }
}
ion-loading.Loader_transparent > div {
  box-shadow: none !important;
}