.ion-color-primary {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

.ion-color-secondary {
	--ion-color-base: var(--ion-color-secondary);
	--ion-color-base-rgb: var(--ion-color-secondary-rgb);
	--ion-color-contrast: var(--ion-color-secondary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-shade);
	--ion-color-tint: var(--ion-color-secondary-tint);
}

.ion-color-tertiary {
	--ion-color-base: var(--ion-color-tertiary);
	--ion-color-base-rgb: var(--ion-color-tertiary-rgb);
	--ion-color-contrast: var(--ion-color-tertiary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
	--ion-color-shade: var(--ion-color-tertiary-shade);
	--ion-color-tint: var(--ion-color-tertiary-tint);
}

.ion-color-success {
	--ion-color-base: var(--ion-color-success);
	--ion-color-base-rgb: var(--ion-color-success-rgb);
	--ion-color-contrast: var(--ion-color-success-contrast);
	--ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
	--ion-color-shade: var(--ion-color-success-shade);
	--ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
	--ion-color-base: var(--ion-color-warning);
	--ion-color-base-rgb: var(--ion-color-warning-rgb);
	--ion-color-contrast: var(--ion-color-warning-contrast);
	--ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
	--ion-color-shade: var(--ion-color-warning-shade);
	--ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
	--ion-color-base: var(--ion-color-danger);
	--ion-color-base-rgb: var(--ion-color-danger-rgb);
	--ion-color-contrast: var(--ion-color-danger-contrast);
	--ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
	--ion-color-shade: var(--ion-color-danger-shade);
	--ion-color-tint: var(--ion-color-danger-tint);
}

.ion-color-light {
	--ion-color-base: var(--ion-color-light);
	--ion-color-base-rgb: var(--ion-color-light-rgb);
	--ion-color-contrast: var(--ion-color-light-contrast);
	--ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
	--ion-color-shade: var(--ion-color-light-shade);
	--ion-color-tint: var(--ion-color-light-tint);
}

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-warner-teal {
	--ion-color-base: var(--ion-color-warner-teal);
	--ion-color-base-rgb: var(--ion-color-warner-teal-rgb);
	--ion-color-contrast: var(--ion-color-warner-teal-contrast);
	--ion-color-contrast-rgb: var(--ion-color-warner-teal-contrast-rgb);
	--ion-color-shade: var(--ion-color-warner-teal-shade);
	--ion-color-tint: var(--ion-color-warner-teal-tint);
}

.ion-color-accent-pale-blue {
	--ion-color-base: var(--ion-color-accent-pale-blue);
	--ion-color-base-rgb: var(--ion-color-accent-pale-blue-rgb);
	--ion-color-contrast: var(--ion-color-accent-pale-blue-contrast);
	--ion-color-contrast-rgb: var(--ion-color-accent-pale-blue-contrast-rgb);
	--ion-color-shade: var(--ion-color-accent-pale-blue-shade);
	--ion-color-tint: var(--ion-color-accent-pale-blue-tint);
}

.ion-color-accent-pale-grey {
	--ion-color-base: var(--ion-color-accent-pale-grey);
	--ion-color-base-rgb: var(--ion-color-accent-pale-grey-rgb);
	--ion-color-contrast: var(--ion-color-accent-pale-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-accent-pale-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-accent-pale-grey-shade);
	--ion-color-tint: var(--ion-color-accent-pale-grey-tint);
}

.ion-color-text-grey {
	--ion-color-base: var(--ion-color-text-grey);
	--ion-color-base-rgb: var(--ion-color-text-grey-rgb);
	--ion-color-contrast: var(--ion-color-text-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-text-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-text-grey-shade);
	--ion-color-tint: var(--ion-color-text-grey-tint);
}

.ion-color-mid-grey {
	--ion-color-base: var(--ion-color-mid-grey);
	--ion-color-base-rgb: var(--ion-color-mid-grey-rgb);
	--ion-color-contrast: var(--ion-color-mid-grey-contrast);
	--ion-color-contrast-rgb: var(--ion-color-mid-grey-contrast-rgb);
	--ion-color-shade: var(--ion-color-mid-grey-shade);
	--ion-color-tint: var(--ion-color-mid-grey-tint);
}

.no-ripple {
  --ripple-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.title_font_family {
  font-family: "Georgia_normal";
}

.temp_circle_button {
  width: 35px;
  height: 35px;
  --border-radius: 50%;
}