.InformationPage {
  width: 100%;
  min-height: calc(100vh - 171px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
}

@media screen and (min-width: 280px) and (max-width: 1324px) {
  .InformationPage > * {
    width: 90% !important;
    height: 90% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .InformationPage span {
    padding: 10px !important;
  } */
  .InformationPage * {
    text-align: center;
  }
}
