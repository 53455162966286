.BLExpandableBox {
  overflow: hidden;

  width: 100%;
  border-radius: 5px;
}
.BLExpandableBox_headerBox {
  --background: transparent;
  --min-height: "20px";
  --background-activated: transparent;
  --ripple-color: var(--background-activated);
  --inner-padding-end: 0px;
}
.BLExpandableBox_label {
  font-weight: unset;
}
.BLExpandableBox_title {
  display: flex;
  align-items: center;
}
.BLExpandableBox_arrow {
  font-size: 24px;
  color: var(--ion-color-secondary);
}

.bl-ExpandableBox_main {
  background-color: var(--ion-color-white);
}

.bl-ExpandableBox_second {
  background-color: var(--ion-color-light);
}

.bl-ExpandableBox_main_isDisabled,
.bl-ExpandableBox_second_isDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
