.MarkdownBreakLine {
  border-width: 1px !important;
  height: 1px !important;
  border: 0;
  border-top: 1px solid rgba(var(--ion-color-black-rgb), 0.1);
  border-bottom: 1px solid rgba(var(--ion-color-white-rgb), 0.3);
}

.MarkdownLink {
  text-decoration: underline;
}

.MarkdownCode {
  background: var(--ion-color-light);
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}

.MarkdownQuote {
  border-left: 2px solid var(--ion-color-light);
  padding: var(--bl-padding-xs);
  margin-left: var(--bl-padding-s);
  font-style: italic;
}

.MarkdownUList {
  list-style-type: initial;
  padding-left: var(--bl-padding-l);
}

.MarkdownOList {
  padding-left: var(--bl-padding-l);
}