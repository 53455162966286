.TVFilterAndDay {
  width: 100%;
  border-bottom: 2px solid var(--ion-color-primary);
  margin-bottom: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.TVFilterAndDay__DaySplits_item,
.TVFilterAndDay__show_all {
  color: #3b3b3b;
}
.TVFilterAndDay__DaySplits_item {
  font-size: 30px;
}
.TVFilterAndDay__show_all {
  font-weight: bold;
}
.TVFilterAndDay__DaySplits {
  position: relative;
}
.TVFilterAndDay__DaySplits > * + * {
  margin-left: 50px;
}
.TVFilterAndDay__show_all {
  font-size: 20px;
  position: absolute;
  right: 40px;
  bottom: 0;
}
.TVFilterAndDay__DaySplits_item_selected,
.TVFilterAndDay__show_all_selected {
  font-weight: bold;
  font-size: 34px;
  color: var(--ion-color-primary);
}
