@font-face {
  font-family: "Georgia_bold";
  font-style: normal;
  src: url("../fonts/georgia_bold.ttf");
}
@font-face {
  font-family: "Georgia_normal";
  font-style: normal;
  src: url("../fonts/georgia_normal.ttf");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  src: url("../fonts/helveticaneue.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}
input {
  outline: none;
  border: none;
}
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  min-height: 100vh;
}