.RestaurantExpanded_Menu {
   display: flex;
   justify-content: center;
}

.RestaurantExpandedIcon {
    width: 24px;
    height: 24px;
    fill: var(--ion-color-secondary);
}

.RestaurantExpandedInfoIcons {
    width: 24px;
    height: 24px;
    filter: invert(14%) sepia(7%) saturate(6085%) hue-rotate(165deg) brightness(94%) contrast(88%);
}

.RestaurantExpandedCenterText {
    text-align: center;
}

.restaurant_unselected_desktop_tile_size {
    min-width: 170px !important;
    max-width: 340px !important;
}

.restaurant_selected_desktop_tile_size {
    min-width: 250px !important;
}

.restaurant_unavail_banner {
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-color-text-grey);
    background-color: rgba(0, 0, 0, .38);
    color: var(--ion-color-white);
    z-index: 2;
}

.additional_details_icon_categories {
    flex: 0 0 calc(calc(5.8 / var(--ion-grid-columns, 12)) * 100%);
    width: calc(calc(5.8 / var(--ion-grid-columns, 12)) * 100%);
    max-width: calc(calc(5.8 / var(--ion-grid-columns, 12)) * 100%);
}

.additional_details_background {
    background-color: var(--ion-color-light) !important; 
}

.additional_details_button_container {
    display: flex;
    justify-content: center;
}

.additional_details_link_button {
    border: 2px solid var(--ion-color-secondary);
    border-radius: "5px";
}

.RestaurantCardMobile_isSelected {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.8;
    background: var(--ion-color-secondary);
}

@media (max-width: 360px) {
    .additional_details_button_container {
        justify-content: flex-start;
    }
}

@media (max-width: 1195px) {
    .RestaurantExpanded_Menu {
        justify-content: flex-start;
    }
}

@media (max-width: 821px) {
    .additional_details_background {
        background-color: var(--ion-color-white) !important; 
    }
}

@media (max-width: 481px) {
    .additional_details_icon_categories {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    }
}

@media (min-width: 821px) {
    .additional_details_icon_categories {
        flex: 0 0 calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
        max-width: calc(calc(12 / var(--ion-grid-columns, 12)) * 100%);
    }
}