.header,
.tabs {
  background-color: var(--ion-color-accent-pale-blue);
}
.header {
  padding: var(--bl-padding-s);
  padding-bottom: 0;
}
.tabs {
  padding-left: 15px;
  box-shadow: inset 0 -1px 1px var(--ion-color-tertiary);
}
.tab {
  background-color: var(--ion-color-white);
  padding-top: var(--bl-padding-s);
  padding-left: var(--bl-padding-s);
  flex-grow: 1;
}
