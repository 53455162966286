.dayBtn {
  width: 200px;
  height: 55px;
  display: flex;
  margin-right: 5px;
  transition: 0.3s ease-in-out all;
  cursor: pointer;
  border: 2px solid var(--ion-color-light);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px;
}
.shrinkDays {
  width: 140px;
}
.day {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  color: var(--ion-color-primary);
  font-weight: bold;
  padding: 5px;
}
/* .day > span {
  margin-right: 5px;
} */
.focused {
  height: 70px;
  border: 4px solid var(--ion-color-light);
  background-color: var(--ion-color-light);
  border-image: linear-gradient(to bottom, var(--ion-color-primary) -20%, var(--ion-color-light) 90%) 1 20%;
  background-image: linear-gradient(var(--ion-color-light), var(--ion-color-light));
  margin-right: 0;
}
.beforeSelect {
  margin-right: 0;
}
.focused > .day {
  flex-direction: column;
}
#wholeStay {
  display: none;
}
/* .dayBtn .dayWrapper::after {
  content: " -";
} */
.focused .dayWrapper::after {
  content: "";
}
.DayButton__whats_on_guide_date {
  font-size: 14px;
}
.DayButton__whats_on_guide_day {
  font-weight: lighter;
  font-size: 14px;
}
@media screen and (min-width: 700px) and (max-width: 1150px) {
  .dayBtn {
    width: 120px;
  }
}

@media screen and (min-width: 280px) and (max-width: 700px) {
  .dayBtn {
    width: 110px;
    height: 50px;
  }
  .focused {
    height: 55px;
    width: 110px;
    background-color: var(--ion-color-light);
  }
  .day {
    flex-direction: column;
    overflow: hidden;
  }
  .dayBtn .dayWrapper::after {
    content: "";
  }
  .timeDisplayed {
    margin-left: 0;
  }
}
