.ShowsExpandedIcon {
  width: 24px;
  height: 24px;
  fill: var(--ion-color-secondary);
}

.ShowsExpandedCenterText {
  text-align: center;
}

.ShowsExpandedCenterDisabledText {
  bottom: 70% !important;
}

.BLItem_check {
  position: absolute;
  color: white;
  right: 7px;
  top: 5px;
  z-index: 6;
}

.BLItemCheckIcon {
  width: 32px;
  height: 32px;
}

.BLItem_triangle {
  width: 0;
  height: 0;
  border-bottom: 70px solid transparent;

  border-right-width: 75px;
  border-right-style: solid;
  border-right-color: var(--ion-color-secondary);
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 5;
}

.BLItem_isSelected {
    border: 2px solid var(--ion-color-secondary);
}

.BLItem_lastfew_banner {
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-white);
  background-color: rgba(0,0,0,.2);
  color: var(--ion-color-white);
  z-index: 2;
  border-radius: 5px 5px 0 0;
  backdrop-filter: blur(18px);
-webkit-backdrop-filter: blur(18px);
}