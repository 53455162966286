.warnerStayButton {
  min-height: 64px;
  color: var(--ion-color-white);
  cursor: pointer;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 4px;
  font-size: 18px;
  border: 3px solid var(--ion-color-primary);
  outline: none;
}
.warnerStayButton:hover {
  box-shadow: 5px 5px 5px #dcdcdc;
  border: 4px solid var(--ion-color-primary);
}

