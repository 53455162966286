.BLAlertPopUp {
  --ion-background-color: var(--ion-color-white);
  background: rgba(0, 0, 0, 0.3);
  --max-width: calc(100% - 48px);
  --height: 100%;
}

.BLAlertPopUp::part(content) {
  --height: fit-content;
  --border-radius: 4px;
  padding: var(--bl-padding-xs);
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  max-height: 90%;
  overflow: auto;
}

.BLAlertPopUpButtonsRow {
  display: flex;
}
