.GeneralActions_desktop {
    justify-content: flex-start;
}
.GeneralActions_text {
    display: flex;
    align-items: center;
}
.GeneralActions_icons {
    color: var(--ion-color-secondary);
}
.GeneralActions_icons svg {
    margin-left: 5px;
}