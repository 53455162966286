.SideMenu {
  margin-top: 80px;
}
.SideMenu_button {
  --background: transparent;
  --background-activated: transparent;
  --box-shadow: none;
  font-weight: 600;
}

.SideMenu_CloseIcon {
  width: 48px;
  height: 48px;
  stroke-width: 40px;
}